import React from 'react';
import './multi-slider-single-picker-question.js.scss';
import useMultiSliderSinglePickerQuestion from '../../hooks/questions/use-multi-slider-single-picker-question';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Button from "@material-ui/core/Button";
import SliderComponent from "../../input-components/slider-component";
import AlertComponent from "../../input-components/alert-component";
import { useTranslation } from "react-i18next";
import { getStyle } from '../styleUtil'
import { Box, Typography } from '@material-ui/core';
import _ from 'lodash';
import { colorTheme } from '../../assets/theme';

// todo differentiate between barrier types
// todo add barrier design when finished

// MultiSliderSinglePickerQuestion component
function MultiSliderSinglePickerQuestion(props) {
    const { template } = props; // Get the template from the props
    const { t } = useTranslation(); // Get the translation function from i18n

    const {
        questionInputName,
        answer,
        questionItems,
        additionalItems,
        answerSum,
        language,
        handleSliderChange,
        addItem,
        removeItem,
        barrierError,
        allRequiredError,
        requiredError,
    } = useMultiSliderSinglePickerQuestion(template); // Get necessary values and functions from the custom hook

    return (
        <Box className='multi-slider-single-picker-container' style={{ marginTop: '70px' }}>
            <Box className='slider-container'>
                <Box style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', gap: '50px', alignItems: 'center' }}>
                    {
                        template.items &&
                        template.items.map((item, index) => {
                            const itemAnswer = answer ? answer.find(a => a.item_id === item.item_id) : null;
                            return (
                                <SliderComponent key={index}
                                    id={item.item_id}
                                    title={item.item_text}
                                    minText={template.items_min_text}
                                    maxText={template.items_max_text}
                                    min={template.items_min}
                                    max={template.items_max}
                                    step={template.items_increment}
                                    defaultValue={template.items_default_value}
                                    value={itemAnswer ? itemAnswer.answer : null}
                                    onChange={handleSliderChange}
                                />
                            )
                        })
                    }
                    {
                        // input from another question
                        template.items_input && questionItems &&
                        questionItems.map((item, index) => {
                            const itemAnswer = answer ? answer.find(a => a.item_id === item.item_id) : null;
                            const itemTitle = _.omit(item, 'item_id');
                            return (
                                <SliderComponent key={index}
                                    id={item.item_id}
                                    title={itemTitle}
                                    minText={template.items_min_text}
                                    maxText={template.items_max_text}
                                    min={template.items_min}
                                    max={template.items_max}
                                    step={template.items_increment}
                                    defaultValue={template.items_default_value}
                                    value={itemAnswer ? itemAnswer.answer : null}
                                    onChange={handleSliderChange}
                                />
                            )
                        })
                    }
                    {
                        template.items_input && questionItems.length === 0 &&
                        <Typography style={{ fontSize: '15px', lineHeight: '20px', color: colorTheme.theme.text.lightGrey }}>{t('makeSelection')} {questionInputName}. {t('additionalOptions')}</Typography>
                    }
                    {
                        additionalItems &&
                        additionalItems.map((item, index) => {
                            const itemAnswer = answer ? answer.find(a => a.item_id === item) : null;
                            return (
                                <SliderComponent key={`additional-${index}`}
                                    id={item}
                                    title={itemAnswer ? itemAnswer.title : ''}
                                    customLabel={template.item_placeholder_text}
                                    minText={template.items_min_text}
                                    maxText={template.items_max_text}
                                    min={template.items_min}
                                    max={template.items_max}
                                    step={template.items_increment}
                                    defaultValue={template.items_default_value}
                                    value={itemAnswer ? itemAnswer.answer : null}
                                    onChange={handleSliderChange}
                                    onRemoveItem={removeItem}
                                    editable={true}
                                />
                            )
                        })
                    }
                    {
                        template.item_other_allowed > 0 || template.items_calculation_sum &&
                        <Box className='controls'>
                            {
                                template.item_other_allowed > 0 ?
                                    <Box className='add-item'>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            className='add-item-button'
                                            startIcon={<ControlPointIcon className='add-icon' style={getStyle('add-icon', language)} />}
                                            onClick={addItem}
                                            disabled={additionalItems.length >= template.item_other_allowed}
                                        >
                                            {template.item_add_text ? template.item_add_text : t("add")}
                                        </Button>
                                    </Box> : <Box />
                            }
                            {
                                template.items_calculation_sum ?
                                    <Box className='sum'>
                                        <span>{template.items_calculation_text ? template.items_calculation_text : t("sum")}</span>
                                        <span>{answerSum}</span>
                                    </Box> : <Box />
                            }
                        </Box>
                    }
                </Box>
                {
                    barrierError() &&
                    <AlertComponent text={template.barrier_text} type='error' disableClose={true} />
                }
                {

                    requiredError && ((template.required && _.isEmpty(answer)) || allRequiredError()) &&
                    <AlertComponent text={template.all_required ? `${t("pleaseAdjust")} **${t("eachSlider")}** ${t("toContinue")}` : `**${t("questionRequired")}** ${t("adjustOneSlider")}`} type='error' />
                }
            </Box>
        </Box>
    );
}

export default MultiSliderSinglePickerQuestion;
