import React from 'react';
import './identity-confirmation-page.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import transformLogoBlack from "../assets/images/black_transform.svg";
import placeholderLogo from "../assets/images/placeholder-logo.svg";
import useIdentityConfirmationPage from '../hooks/use-identity-confirmation-page';
import PrimaryButton from '../components/buttons/primary-button';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { colorTheme } from '../assets/theme';
import { ReactComponent as CheckedIcon } from '../assets/images/checked-icon.svg';
import { useTheme } from '@material-ui/core/styles';
import { ReactComponent as UnCheckedIcon } from '../assets/images/unchecked-icon.svg';

// Constants
const ID_VALUE_YES = 'ID_VALUE_YES';

// IdentityConfirmationPage component
function IdentityConfirmationPage(props) {
    const { t } = useTranslation(); // i18n translation hook
    const theme = useTheme(); // Get theme
    const isSmall = useMediaQuery(theme.breakpoints.down('xs')); // Check if screen size is small


    const {
        userName,
        tokenError,
        companyLogo,
        value,
        identityNotConfirmed,
        handleChange,
        handleNextClick,
        getConfirmationText,
        getConfirmationTitle
    } = useIdentityConfirmationPage({ ...props, t }); // Get the variables and functions from the hook

    return (
        <Box className='identity-confirmation-container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
            <Box className='header' sx={{ width: '100%', maxHeight: isSmall ? '200px' : '360px', minHeight: isSmall ? '200px' : '360px', boxSizing: 'border-box', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'height 1s', position: 'relative' }}>
                {
                    userName &&
                    <Box className='logo-container' display={'flex'} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <img className="company-logo" src={companyLogo ? companyLogo : placeholderLogo} alt="company-logo" style={{ maxWidth: isSmall ? '250px' : '350px', width: 'auto', maxHeight: isSmall ? '100px' : '150px' }} />
                    </Box>
                }
            </Box>
            <Box className='identity-confirmation-content' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1', maxWidth: '90%', textAlign: 'center', justifyContent: 'center', margin: '50px 0px' }}>
                {
                    userName ?
                        identityNotConfirmed ?
                            <Typography variant="h2" className='no-confirmed' style={{ fontSize: '36px', fontWeight: '400', color: colorTheme.theme.text.darkGrey, maxWidth: '740px' }}>{t("nameNotConfirmed")}</Typography>
                            :
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gridGap={'32px'} maxWidth={'740px'}>
                                <Typography variant="h2" className='welcome' style={{ fontSize: '40px', fontWeight: '600', color: colorTheme.theme.text.darkGrey }}>{getConfirmationTitle()}</Typography>
                                <Typography variant="body1" style={{ fontSize: '16px', lineHeight: '24px', color: colorTheme.theme.text.mediumGrey, letterSpacing: '0.15px' }}>{getConfirmationText()}</Typography>
                                <FormControl component="fieldset" className='checkbox'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={ID_VALUE_YES}
                                                icon={<UnCheckedIcon />}
                                                checkedIcon={<CheckedIcon className={value === ID_VALUE_YES ? 'checked-icon' : ''} />}
                                                checked={value === ID_VALUE_YES}
                                                onChange={e => handleChange(e.target.checked)}
                                                name="confirm"
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Typography style={{ color: colorTheme.theme.text.darkGrey }}>
                                                {t("understandTerms")}
                                            </Typography>}
                                    />
                                </FormControl>
                                <Box mt={'-16px'}>
                                    <PrimaryButton visible={value} onClick={handleNextClick} title={t("next")} iconPosition={'right'} buttonType={'highlight'} />
                                </Box>
                            </Box>
                        :
                        (
                            tokenError ?
                                <Typography variant="h2" className='error' style={{ fontSize: '36px', fontWeight: '400', color: colorTheme.theme.text.darkGrey, maxWidth: '740px' }}>
                                    {tokenError}
                                </Typography> :
                                <CircularProgress className='spinner'/>
                        )
                }
            </Box>
            <img className="transform-logo" src={transformLogoBlack} alt="transform-logo" style={{ marginTop: 'auto', marginBottom: '20px' }} />
        </Box>
    );
}

export default IdentityConfirmationPage;
